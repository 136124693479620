import { timingFunctions } from 'polished'

const theme = {
  color: {
    primary: [
      '#e1cfff',
      '#b78bff',
      '#995df9',
      '#712ce0',
      '#522c8e',
      '#351d5b',
      '#1d1131'
    ],
    accent: [
      '#CBFEFC',
      '#A7FCED',
      '#86FEE9',
      '#00E6BE',
      '#13AD92',
      '#20695D',
      '#1B3A35'
    ],
    grey: [
      '#f2f3f9',
      '#e7e8ee',
      '#d7d9e1',
      '#a5a9b7',
      '#6a6d7b',
      '#40434f',
      '#232531'
    ],
    primaryColor: '#712ce0',
    accentColor: '#00E6BE',
    chartBg: '#fff',
    chartLineBg: '#e7e8ee',
    pageBg: '#f2f3f9',
    error: '#E15C7E',
  },
  text: {
    headingFamily: 'Josefin Sans, sans-serif',
    fontFamily: 'Karla, sans-serif',
    fontColor: '#40434f',
    fontBaseSize: '1.5rem'
  },
  button: {
    sm: '1.5rem',
    md: '1.7rem',
    lg: '2rem'
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '1148px',
    xl: '1440px',
    hd: '1920px',
    full: '100%'
  },
  radius: {
    button: '4px',
    box: '6px'
  },
  animation: timingFunctions('easeInOutCubic')
}

const devices = {
  desktopHD: `max-width: ${ theme.breakpoints.hd }`,
  desktopWide: `max-width: ${ theme.breakpoints.xl }`,
  desktop: `max-width: ${ theme.breakpoints.lg }`,
  tablet: `max-width: ${ theme.breakpoints.md }`,
  tabletWide: 'max-width: 980px',
  mobile: `max-width: ${ theme.breakpoints.sm }`
}
export { theme as default, devices }
