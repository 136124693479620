import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'

import { devices } from '../../../theme'

const Button = styled(Link)`
  font-size: ${ props => props.sizing ? props.sizing : props.theme.button['lg'] };
  line-height: 1;
  padding: 0.6em 1em;
  display: inline-block;
  border-radius: ${ props => props.theme.radius.button };
  transition: all 0.25s ${ props => props.theme.animation };
  border: 0;
  cursor: pointer;

  & + & {
    margin-left: 1em;
  }

  :focus {
    outline: 0;
  }

  ${ ({ appearance }) => {
    switch (appearance) {
    case 'primary':
      return Primary
    case 'secondary':
      return Secondary
    case 'link':
      return LinkStyle
    case 'disabled':
      return Disabled
    }
  } }

  ${ ({ icon }) => icon && css`
    padding-right: 2.1em;
    background-image: url(${ icon });
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 20px;
  ` }

  @media (${ devices.tablet }) {
    font-size: 1.6rem;
  }
`

const Primary = css`
  background-color: ${ props => props.theme.color.primaryColor };
  color: #fff;
  border: 1px solid ${ props => props.theme.color.primaryColor };
  box-shadow: 0 2px 8px -1px ${ props => lighten(0.5, props.theme.color.primary[5]) };

  :hover {
    background-color: ${ props => lighten(0.05, props.theme.color.primaryColor) };
    box-shadow: 0 4px 8px -1px ${ props => lighten(0.5, props.theme.color.primary[5]) };
  }

  :focus {
    box-shadow: 0 0 0.5px 4px
      ${ props => lighten(0.12, props.theme.color.primary[1]) };
  }
`

const Secondary = css`
  background-color: #fff;
  color: ${ props => props.theme.text.fontColor };
  border: 1px solid ${ props => props.theme.color.grey[2] };
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${ props => props.theme.color.grey[0] };
    border-radius: ${ props => props.theme.radius.button };
    width: 100%;
    height: 100%;
    transform-origin: left;
    transform: scaleX(0);
    transition: all 0.25s ${ props => props.theme.animation };
    z-index: -1;
  }

  :hover {
    background-color: transparent;
    color: ${ props => props.theme.color.grey[6] };
    border-color: ${ props => props.theme.color.grey[4] };

    &::before {
      transform: scaleX(1);
    }
  }

  :focus {
    box-shadow: 0 0 0.5px 4px ${ props => props.theme.color.grey[2] };
  }
`
const LinkStyle = css`
  color: ${ props => props.theme.color.primaryColor };
  border-bottom: 2px solid;
  border-radius: 0;
  padding: 0;
  padding-bottom: 0.3em;

  &::after {
    content: "→";
    transform: translateX(0.3em);
    display: inline-block;
    transition: transform 0.25s ${ props => props.theme.animation };
  }

  :hover {
    color: ${ props => props.theme.color.primary[2] };

    &::after {
      transform: translateX(0.8em);
    }
  }
`

const Disabled = css`
  background-color: ${ props => props.theme.color.grey[2] };
  color: ${ props => props.theme.color.grey[4] };
  border-color: transparent;
  box-shadow: none;
  cursor: not-allowed;

  :hover {
    background-color: ${ props => props.theme.color.grey[2] };
    box-shadow: none;
  }
`

const ButtonGroup = styled.div`
  ${ Button } {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: 0;
    }
  }
`

Button.propTypes = {
  appearance: PropTypes.oneOf(
    ['primary', 'secondary', 'link', 'disabled'
    ]).isRequired,
  sizing: PropTypes.oneOf(['sm', 'md', 'lg'])
}

export { Button as default, ButtonGroup }
