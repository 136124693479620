export const LABELS = {
  '1M': 30,
  '6M': 180,
  '1Y': 365,
  all: 'all'
}

export const TABLE_HEADER_NAME = {
  NAME: 'Name',
  PRICE: 'Price (24h)',
  CHANGE: 'Change',
  MCAP: 'Market Cap',
  VOLUME: 'Volume (24h)'
}

export const TABLE_SORT_KEY = {
  NAME: 'name',
  PRICE: 'price_usd',
  CHANGE: 'change',
  MCAP: 'market_cap',
  VOLUME: 'volume_usd'
}
