import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rgba, timingFunctions } from 'polished'
import { Container, Row, Column } from '../layout/Grid'
import Button from '../forms/Buttons'
import theme, { devices } from '../../../theme'

import logo from '../../../images/logo.svg'
import dataSales from '../../../images/products/data_sales_icon.svg'
import dataApi from '../../../images/products/data_api_icon.svg'

const Navigation = [
  {
    title: 'About',
    path: '/about'
  },
  {
    title: 'Products',
    path: '#',
    dropdown: [
      {
        title: 'Data Sales',
        description: 'Historical market data for exchanges and currency, to fit your needs.',
        icon: dataSales,
        path: '/products/data-sales'
      },
      {
        title: 'Data API',
        description:
          'Unified market API for all your integration needs.',
        icon: dataApi,
        path: '/products/data-api'
      }
    ]
  },
  {
    title: 'ICI 15',
    path: '/assets'
  },
  {
    title: 'FAQ',
    path: '/faq'
  }
]

const HeaderContainer = styled.header`
  background-color: #fff;

  @media (${ devices.tabletWide }) {
    overflow: hidden;

    ${ Column } {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  @media (${ devices.mobile }) {
    ${ Column } {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`

const Logo = styled.div`
  background-image: url(${ logo });
  background-repeat: no-repeat;
  width: 200px;
  height: 50px;
  display: inline-block;
`

const Nav = styled.nav`
  a,
  span {
    text-decoration: none;
    display: inline-block;
    color: ${ props => props.theme.text.fontColor };
    font-size: 1.9rem;
    padding: 1.2em;
    transition: all 0.25s ${ props => props.theme.animation };
    position: relative;

    &.active,
    :hover,
    :focus {
      color: ${ props => props.theme.color.primaryColor };
      outline: 0;
    }
  }
`

const Links = styled(Column)`
  @media (${ devices.desktop }) {
    transform: translateX(
      ${ props => (props.active ? '0' : 'calc(100% + 1px)') }
    );
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${ props => props.theme.color.primary[6] };
    z-index: 1000;
    box-shadow: ${ props =>
    props.active && `0px 0 20px ${ rgba(props.theme.color.primary[6], 0.45) }` };
    transition: box-shadow, transform 0.25s ${ timingFunctions('easeOutCubic') };

    nav {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        padding: 0.7em;
        margin-right: 0;
        padding-left: 1.5em;
      }
    }

    a {
      color: #fff;
      box-shadow: none;

      :hover {
        box-shadow: none;
      }
    }

    ${ Button } {
      margin-top: 1em;
      margin-left: 1.5em;
    }
  }

  @media (${ devices.mobile }) {
    width: 100%;
  }
`

const BurgerOuter = styled(Column)`
  display: none;

  @media (${ devices.desktop }) {
    display: inherit;
  }

  @media (${ devices.mobile }) {
    position: absolute;
    right: 2em;
    top: 2.5em;
  }
`

const Burger = styled.button`
  background-color: transparent;
  border: 0;
  appearance: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  font-size: 0;
  z-index: 1100;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${ props =>
    props.active ? '#fff' : props.theme.color.primary[6] };
    transition: background-color, transform 0.25s ease;
    transform-origin: center;
  }

  &::before {
    top: ${ props => (props.active ? '50%' : '0') };
    transform: ${ props => props.active && 'rotate(45deg)' };
  }

  &::after {
    top: ${ props => (props.active ? '50%' : '12px') };
    transform: ${ props => props.active && 'rotate(-45deg)' };
  }
`

const Dropdown = styled.ul`
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform-origin: top;
  transform: translate(-50%, -0.15em) scaleY(0.95);
  padding: 0;
  margin: 0;
  width: 400px;
  border: 1px solid ${ props => props.theme.color.grey[1] };
  box-shadow: 0 5px 20px ${ props => props.theme.color.grey[0] };
  z-index: 1000;
  transition: all 0.25s ${ timingFunctions('easeOutQuad') };

  &::before {
    content: "";
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: -8px;
    width: 18px;
    height: 18px;
    transform: translateX(-50%) rotate(45deg);
    box-shadow: -1px -1px 0 ${ props => props.theme.color.grey[1] };
  }

  li {
    list-style: none;
  }

  @media (min-width: ${ theme.breakpoints.lg }) {
    a {
      :hover {
        background-color: ${ props => rgba(props.theme.color.primary[0], 0.12) };
      }
    }
  }

  a {
    margin: 0.5em;
    padding: 0.5em;
    display: flex;
    align-items: center;
    border-radius: 4px;


    img {
      min-width: 54px;
      height: 54px;
      margin-right: 0.7em;
    }

    h4,
    p {
      margin: 0;
      padding: 0;
      font-size: 90%;
    }
  }

  @media (${ devices.desktop }) {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    border-color: transparent;
    position: static;
    transform: none;
    box-shadow: none;

    &::before {
      display: none;
    }

    img,
    p {
      display: none;
    }

    a {
      padding: 0;
      margin: 0;
    }
  }
`

const DropdownLink = styled.span`
  position: relative;
  cursor: pointer;

  @media (min-width: ${ theme.breakpoints.lg }) {
    :hover {
      ${ Dropdown } {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, 0) scaleY(1);
      }
    }
  }

  @media (${ devices.desktop }) {
    padding: 0;
    margin: 0;
  }
`

const Header = () => {
  const [active, setActive] = useState(false)

  return (
    <HeaderContainer>
      <Container
        align="center"
        justify="space-between"
      >
        <Row align="center">
          <Column>
            <Link
              to="/"
              css={`
                @media (${ devices.tablet }) {
                  width: 200px;
                }
              `}
              aria-label="Back to Home"
            >
              <Logo />
            </Link>
          </Column>
          <Links
            dir="row"
            justify="flex-end"
            align="center"
            active={active}
            onClick={() => setActive(false)}
          >
            <Nav>
              {Navigation.map((link, idx) => {
                if (link.dropdown) {
                  return (
                    <DropdownLink key={idx}>
                      {link.title}
                      <Dropdown>
                        {link.dropdown.map((dlink, idx) => (
                          <li key={idx}>
                            <Link to={dlink.path}>
                              {dlink.icon && (
                                <img
                                  src={dlink.icon}
                                  alt={dlink.title}
                                />
                              )}
                              <div>
                                <h4>{dlink.title}</h4>
                                <p>{dlink.description}</p>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </Dropdown>
                    </DropdownLink>
                  )
                }
                return (
                  <Link
                    key={idx}
                    activeClassName="active"
                    to={link.path}
                  >
                    {link.title}
                  </Link>
                )
              })}
            </Nav>
            <Button
              appearance="primary"
              to="/price"
            >
              Market Prices
            </Button>
          </Links>
          <BurgerOuter
            align="flex-end"
            justify="center"
          >
            <Burger
              active={active}
              onClick={() => setActive(!active)}
            >
              Menu
            </Burger>
          </BurgerOuter>
        </Row>
      </Container>
    </HeaderContainer>
  )
}
export { Header as default, Nav }
