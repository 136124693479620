// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-template-markdown-js": () => import("/opt/build/repo/src/templates/template-markdown.js" /* webpackChunkName: "component---src-templates-template-markdown-js" */),
  "component---src-templates-template-asset-js": () => import("/opt/build/repo/src/templates/template-asset.js" /* webpackChunkName: "component---src-templates-template-asset-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-canceled-js": () => import("/opt/build/repo/src/pages/account/canceled.js" /* webpackChunkName: "component---src-pages-account-canceled-js" */),
  "component---src-pages-account-register-js": () => import("/opt/build/repo/src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-success-js": () => import("/opt/build/repo/src/pages/account/success.js" /* webpackChunkName: "component---src-pages-account-success-js" */),
  "component---src-pages-assets-js": () => import("/opt/build/repo/src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("/opt/build/repo/src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-products-data-api-js": () => import("/opt/build/repo/src/pages/products/data-api.js" /* webpackChunkName: "component---src-pages-products-data-api-js" */),
  "component---src-pages-products-data-sales-js": () => import("/opt/build/repo/src/pages/products/data-sales.js" /* webpackChunkName: "component---src-pages-products-data-sales-js" */)
}

