import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rgba, timingFunctions } from 'polished'

import { devices } from '../../theme'
import Button, { ButtonGroup } from './forms/Buttons'
import { StoreCtx } from '../../store'

const Popup = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 2em;
  left: 2em;
  background-color: #fff;
  box-shadow: 0 4px 10px ${ props => rgba(props.theme.color.primary[6], 0.12) };
  padding: 0.5em 0.7em;
  border-radius: 4px;
  z-index: 1000;
  transform: ${ props => (props.visible ? 'translateY(0)' : 'translateY(200%)') };
  transition: transform 0.5s ${ timingFunctions('easeOutBack') };

  a {
    color: ${ props => props.theme.color.primaryColor };

    :hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 1.4rem;
    margin: 0;
  }

  ${ Button } {
    font-size: 1.4rem;
  }

  ${ ButtonGroup } {
    margin-left: 0.5em;
  }

  @media (${ devices.tablet }) {
    flex-wrap: wrap;
    justify-content: center;
    right: 2em;
    padding: 0.8em;
    text-align: center;

    p {
      margin-bottom: 1em;
    }
  }
`

const CookieBanner = () => {
  return (
    <StoreCtx.Consumer>
      {({ data: { cookie }, actions }) => (
        <Popup visible={cookie === null}>
          <p>
            👋 We use cookies for analytics in accordance with our{' '}
            <Link to="/privacy-policy">Privacy Policy.</Link>{' '}
          </p>
          <ButtonGroup>
            <Button
              onClick={() => actions.setCookieTracking('accept')}
              as="button"
              appearance="primary"
            >
              Accept
            </Button>
            <Button
              as="button"
              appearance="secondary"
              onClick={() => actions.setCookieTracking('reject')}
              css={`
                color: ${ props => props.theme.color.grey[3] };
              `}
            >
              ✖
            </Button>
          </ButtonGroup>
        </Popup>
      )}
    </StoreCtx.Consumer>
  )
}

export default CookieBanner
