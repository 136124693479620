import React from 'react'
import PropTypes from 'prop-types'

import Store from '../store'
import ThemeProvider from '../theme/global'
import Header from '../components/ui/header'
import Footer from '../components/ui/footer'
import CookieBanner from '../components/ui/CookieBanner'

const Layout = ({ children }) => (
  <Store>
    <ThemeProvider>
      <CookieBanner />
      <Header />
      {children}
      <Footer />
    </ThemeProvider>
  </Store>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
