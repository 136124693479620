import { LABELS } from './constants'
import fetch from 'node-fetch'

export const windowGlobal = typeof window !== 'undefined' && window

export async function fetchData (path, options = {}) {
  // Include request method in the options
  // { method: 'POST', mode, cache, etc.}
  const conf = {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    ...options
  }
  try {
    const res = await fetch(path, conf)
    return await res.json()
  } catch (e) {
    return console.error('Failed to perform fetch request: \n', e)
  }
}

export async function setRange (range) {
  await this.setState({
    data: {
      ...this.state.data,
      range: range
    }
  })
}

function sortTableData (state, action) {
  return state.data.sort((a, b) => {
    if (action.column === 'name') {
      return state.sortDirection === 'DESC'
        ? a.node.coin.name.localeCompare(b.node.coin.name)
        : b.node.coin.name.localeCompare(a.node.coin.name)
    }

    return state.sortDirection === 'DESC'
      ? parseInt(b.node.coin[action.column]) -
      parseInt(a.node.coin[action.column])
      : parseInt(a.node.coin[action.column]) -
      parseInt(b.node.coin[action.column])
  })
}

export function tableReducer (state, action) {
  if (action.type === 'SORT_COLUMN') {
    const sortedData = sortTableData(state, action)
    return {
      ...state,
      data: sortedData,
      sortColumn: action.column,
      sortDirection: state.sortDirection === 'DESC' ? 'ASC' : 'DESC'
    }
  } else {
    return state
  }
}

export function setDataRange (data, range) {
  const totalLength = data.length
  const dataLength = totalLength - LABELS[range]

  return data.slice(dataLength || 0, totalLength)
}

export function transformData (data) {
  return data.map(entry => {
    return {
      x: new Date(entry.x),
      y: entry.y,
      final: entry.final
    }
  })
}

export function getAverage (data) {
  const allPrice = data.map(price => price.y)
  const lastPrice = allPrice[allPrice.length - 1]

  const priceSum = allPrice.reduce((acc, current) => acc + current)

  return {
    lastPrice: lastPrice,
    rangeLastPrice: allPrice[0],
    average: priceSum / allPrice.length,
    difference: function () {
      return {
        price: lastPrice - this.rangeLastPrice,
        isNegative: function () {
          return Math.sign(this.price) !== 1
        }
      }
    },
    differenceInPercent: function () {
      const result =
        ((this.lastPrice - this.rangeLastPrice) / this.rangeLastPrice) * 100

      return `${ result.toFixed(2) }%`
    }
  }
}

export function setPriceDecimal (price) {
  const priceNum = typeof price === 'string' ? parseInt(price) : price

  if (priceNum >= 1e3) {
    return priceNum.toLocaleString().slice(0, -1)
  } else if (priceNum < 1) {
    return priceNum.toFixed(6)
  } else {
    return priceNum.toFixed(2)
  }
}

export function setLargeNumberFormat (number) {
  const strNum = number.toLocaleString('de-DE')
  if (number < 1e6) return number.toLocaleString()

  return number < 1e9 ? `${ strNum.slice(0, -6) }M` : `${ strNum.slice(0, -9) }B`
}

export async function setCookieTracking (condition) {
  // Initial state is null if there is no cookie set
  if (!window.localStorage.getItem('cookie')) {
    await this.setState({
      data: {
        ...this.state.data,
        cookie: null
      }
    })
  } else {
    // Or remember from previous session
    await this.setState({
      data: {
        ...this.state.data,
        cookie: window.localStorage.getItem('cookie')
      }
    })
  }
  // Set the condition based on the button clicked
  if (condition) {
    if (condition === 'reject' && window.ga) window.gaOptout()
    await this.setState(
      {
        data: {
          ...this.state.data,
          cookie: condition
        }
      },
      () => window.localStorage.setItem('cookie', condition)
    )
  }
}

export async function setNewAccount (account = {}) {
  await this.setState({
    data: {
      ...this.state.data,
      newAccount: {
        ...account,
        selectedPackage: {
          ...this.state.data.newAccount.selectedPackage
        }
      }
    }
  })
}

export async function setPackageSelection (selectedPackage) {
  await this.setState({
    data: {
      ...this.state.data,
      newAccount: {
        ...this.state.data.newAccount,
        selectedPackage: {
          ...selectedPackage
        }
      }
    }
  })
}

export const isEmpty = obj => {
  // https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
  if (typeof obj === 'undefined') throw Error('Provide an object')
  return Object.entries(obj).length === 0 && obj.constructor === Object
}
