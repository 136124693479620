import React from 'react'
import { Link } from 'gatsby'
import { Nav } from '../header'
import styled from 'styled-components'
import { darken } from 'polished'

import { Container, Row, Column } from '../layout/Grid'
import theme, { devices } from '../../../theme'

const FooterContainer = styled.footer`
  background-color: ${ props => darken(0.03, props.theme.color.primary[6]) };
  padding: 1.5em;

  h4, span {
    margin: 0;
    color: ${ props => props.theme.color.primary[0] };
  }

  h4 {
    color: #fff;
  }

  a {
    color: ${ props => props.theme.color.accentColor };
  }

  @media (${ devices.mobile }) {
    ${ Row } {
      margin: 0.5em 0;
    }
    padding: 0.5em;

    ${ Column } {
      padding: 0.5em 0;
      align-items: flex-start;
    }
  }
`

const currentYear = () => new Date().getFullYear()

const Footer = () => (
  <FooterContainer>
    <Container>
      <Row>
        <Column>
          <Nav>
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Nav>
        </Column>
        <Column>
          <Row
            mt="0"
            mb="0"
          >
            <Column
              align="flex-end"
              dir="column"
            >
              <h4>Location</h4>
              <span>Toronto, Canada</span>
            </Column>
          </Row>
        </Column>
      </Row>
      <Row
        style={{
          borderTop: `1px solid ${ theme.color.primary[5] }`,
          paddingTop: '1.7em'
        }}
      >
        <Column>
          <span>Made with lots of ☕</span>
        </Column>
        <Column
          justify="flex-end"
          style={{ textAlign: 'right' }}
        >
          <span>&copy; {currentYear()} Investabit</span>
        </Column>
      </Row>
    </Container>
  </FooterContainer>
)

export default Footer
