import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme, { devices } from './index'
import 'normalize.css'

const GlobalStyle = createGlobalStyle`
	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		box-sizing: border-box;
}

  html {
  		font-display: swap; 
		font-size: 62.5%;
  }

	body {
		font-size: 1.7rem;
		font-family: ${ props => props.theme.text.fontFamily };
		line-height: 1.745;

    @media (${ devices.tabletWide }) {
      overflow-x: hidden;
    }
	}

	h1, h2, h3 {
		font-family: ${ props => props.theme.text.headingFamily };
		color: ${ props => props.theme.color.grey[5] };
	}

	h4, h5, h6 {
		font-family: ${ props => props.theme.text.fontFamily };
	}

	a {
		text-decoration: none;
	}

	img {
		max-width: 100%;
		height: auto;
	}

  p > a {
    color: ${ props => props.theme.color.primaryColor };
    border-bottom: 1px solid ${ props => props.theme.color.primaryColor };
  }
`

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
)
