import React, { Component } from 'react'
import {
  setRange,
  setCookieTracking,
  setNewAccount,
  setPackageSelection
} from '../utils'

const StoreCtx = React.createContext()

class Store extends Component {
  constructor () {
    super()
    this.state = {
      data: {
        range: '6M',
        cookie: null,
        newAccount: {
          name: '',
          email: '',
          password: '',
          selectedPackage: {}
        }
      },
      actions: {
        setRange: setRange.bind(this),
        setCookieTracking: setCookieTracking.bind(this),
        setNewAccount: setNewAccount.bind(this),
        setPackageSelection: setPackageSelection.bind(this)
      },
      status: 'ready'
    }
    this.setDefaultCookie.bind(this)
  }
  setDefaultCookie = setCookieTracking;

  async componentDidMount () {
    this.setDefaultCookie()
  }

  render () {
    const { children } = this.props
    return <StoreCtx.Provider value={this.state}>{children}</StoreCtx.Provider>
  }
}

export { Store as default, StoreCtx }
