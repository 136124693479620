import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { stripUnit } from 'polished'
import { devices } from '../../../theme'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: ${ props => props.bgColor };
`

const Container = styled.div`
  max-width: ${ props => props.theme.breakpoints[props.size] || props.theme.breakpoints.lg };
  margin: 0 auto;
  display: flex;
  align-items: ${ props => props.align };
  justify-content: ${ props => props.justify };
  flex-flow: row wrap;

  @media (max-width: ${ props => stripUnit(props.theme.breakpoints.desktop) - 1 }px) {
    width: 100%;
  }

  @media (${ devices.desktop }) {
    max-width: 100%;
    padding: 0 2em;
  }

  @media (${ devices.tablet }) {
    max-width: 100%;
    padding: 0 1em;
  }
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: ${ props => (props.dir ? props.dir : 'column') };
  align-items: ${ props => props.align };
  justify-content: ${ props => props.justify };

  @media (${ devices.tablet }) {
    order: ${ props => props.orderBy };
  }
`

const Row = styled.div`
  background-color: ${ props => props.bg };
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${ props => props.dir || 'row' };
  align-items: ${ props => props.align };
  justify-content: ${ props => props.justify };
  margin-top: ${ props => (props.mt ? props.mt : '1.7em') };
  margin-bottom: ${ props => (props.mb ? props.mb : '1.7em') };

  ${ ({ grid, gutter }) => {
    const gridSize = (grid * 100).toFixed(2) + '%'

    if (!grid) return null

    if (gutter) {
      return css`
        ${ Column } {
          max-width: calc(${ gridSize } - ${ gutter * 2 }em);
          flex-basis: calc(${ gridSize } - ${ gutter * 2 }em);
          margin: ${ gutter }em;
        }
      `
    }

    return css`
      ${ Column } {
        flex-basis: ${ gridSize };
        max-width: ${ gridSize };
      }
    `
  } }

  @media (${ devices.tablet }) {
    flex-direction: initial;
    padding: 0;

    ${ Column } {
    flex-basis: 100%;
    max-width: 100%;
    }
  }

  @media (${ devices.mobile }) {
    margin: 2em 0;
    padding: 0;
  }

`
const Inner = styled.div`
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  margin-left: 1.2em;
  margin-right: 1.2em;
`

const alignProps = {
  dir: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
}

Container.propTypes = {
  ...alignProps
}

Row.propTypes = {
  grid: PropTypes.number,
  gutter: PropTypes.number,
  ...alignProps
}

Column.propTypes = {
  orderBy: PropTypes.number,
  ...alignProps
}

Wrapper.propTypes = {
  bgColor: PropTypes.string
}

export { Wrapper, Container, Row, Column, Inner }
